import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { LoginComponent } from './login/login.component';

import { ThanhVienChucNangComponent } from './thanh-vien-chuc-nang/thanh-vien-chuc-nang.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienBlockTransationComponent } from './thanh-vien-block-transation/thanh-vien-block-transation.component';
import { ThanhVienBlockComponent } from './thanh-vien-block/thanh-vien-block.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { DanhMucDichVuComponent } from './danh-muc-dich-vu/danh-muc-dich-vu.component';
import { DonHangComponent } from './don-hang/don-hang.component';
import { DanhMucUngDungComponent } from './danh-muc-ung-dung/danh-muc-ung-dung.component';
import { DanhMucGasComponent } from './danh-muc-gas/danh-muc-gas.component';

const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: 'Login', component: LoginComponent,
  },
  {
    path: 'Homepage', component: HomepageComponent,
  },
  {
    path: 'DanhMucChucNang', component: DanhMucChucNangComponent,
  },
  {
    path: 'DanhMucThanhVien', component: DanhMucThanhVienComponent,
  },
  {
    path: 'DanhMucDichVu', component: DanhMucDichVuComponent,
  },
  {
    path: 'DanhMucUngDung', component: DanhMucUngDungComponent,
  },
  {
    path: 'DanhMucGas', component: DanhMucGasComponent,
  },


  {
    path: 'ThanhVienChucNang', component: ThanhVienChucNangComponent,
  },
  {
    path: 'ThanhVien', component: ThanhVienComponent,
  },
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },
  {
    path: 'ThanhVienBlock', component: ThanhVienBlockComponent,
  },
  {
    path: 'ThanhVienBlockTransation', component: ThanhVienBlockTransationComponent,
  },

  {
    path: 'DonHang', component: DonHangComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































