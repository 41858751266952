<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ThanhVienBlockTransationDetail" data-bs-toggle="tab" href="#ThanhVienBlockTransationDetail"
                role="tab" aria-controls="ThanhVienBlockTransationDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Transaction
                    [{{ThanhVienBlockTransationService.FormData.ID}}]</span>
            </a>
        </li>      
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="fa fa-window-close"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ThanhVienBlockTransationDetail" role="tabpanel">           
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Token (Tự động)</label>
                        <input name="Code" [(ngModel)]="ThanhVienBlockTransationService.FormData.Code" placeholder="Token"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi nhận (Tự động)</label>
                        <input [ngModel]="ThanhVienBlockTransationService.FormData.NgayGhiNhan | date:'yyyy-MM-dd HH:mm:ss'"
                            style="text-align: right;" (ngModelChange)="DateNgayGhiNhan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="datetime" class="form-control" autocomplete="off">
                    </div>                    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sender</label>
                        <input name="DiaChiChuyen" [(ngModel)]="ThanhVienBlockTransationService.FormData.DiaChiChuyen"
                            placeholder="Sender" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Send To</label>
                        <input name="DiaChiNhan" [(ngModel)]="ThanhVienBlockTransationService.FormData.DiaChiNhan"
                            placeholder="Send To" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Khu vực</label>
                        <input name="KhuVuc" [(ngModel)]="ThanhVienBlockTransationService.FormData.KhuVuc"
                            placeholder="Khu vực" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Type</label>
                        <input name="TypeName" [(ngModel)]="ThanhVienBlockTransationService.FormData.TypeName" placeholder="Type"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô tả (Function)</label>
                        <input name="Description" [(ngModel)]="ThanhVienBlockTransationService.FormData.Description"
                            placeholder="Mô tả (Function)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giá trị</label>
                        <input name="ThanhTienSauCung" [(ngModel)]="ThanhVienBlockTransationService.FormData.ThanhTienSauCung"
                            placeholder="Giá trị" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Transaction</label>
                        <input name="SoLuongTransation" [(ngModel)]="ThanhVienBlockTransationService.FormData.SoLuongTransation"
                            placeholder="Transaction" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Gas</label>
                        <input name="SoLuongGas" [(ngModel)]="ThanhVienBlockTransationService.FormData.SoLuongGas"
                            placeholder="Gas" type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tháng</label>
                        <input name="SoLuongThang" [(ngModel)]="ThanhVienBlockTransationService.FormData.SoLuongThang"
                            placeholder="Tháng" type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="ThanhVienBlockTransationService.FormData.Note"
                            placeholder="Ghi chú" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Content</label>
                        <textarea name="HTMLContent" [(ngModel)]="ThanhVienBlockTransationService.FormData.HTMLContent"
                            placeholder="Content" type="text" class="form-control" rows="30"></textarea>
                    </div> 
                </div>
            </div>
        </div>       
    </div>
</div>
<app-loading *ngIf="ThanhVienBlockTransationService.IsShowLoading"></app-loading>