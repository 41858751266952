import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { DanhMucGas } from 'src/app/shared/DanhMucGas.model';
import { DanhMucGasService } from 'src/app/shared/DanhMucGas.service';

@Component({
  selector: 'app-danh-muc-gas',
  templateUrl: './danh-muc-gas.component.html',
  styleUrls: ['./danh-muc-gas.component.css']
})
export class DanhMucGasComponent implements OnInit {

  @ViewChild('DanhMucGasSort') DanhMucGasSort: MatSort;
  @ViewChild('DanhMucGasPaginator') DanhMucGasPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucGasService: DanhMucGasService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucGasSearch() {
    this.DanhMucGasService.SearchAll(this.DanhMucGasSort, this.DanhMucGasPaginator);
  }
  DanhMucGasSave(element: DanhMucGas) {
    this.DanhMucGasService.FormData = element;
    this.NotificationService.warn(this.DanhMucGasService.ComponentSaveAll(this.DanhMucGasSort, this.DanhMucGasPaginator));
  }
  DanhMucGasDelete(element: DanhMucGas) {
    this.DanhMucGasService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucGasService.ComponentDeleteAll(this.DanhMucGasSort, this.DanhMucGasPaginator));
  }  
}