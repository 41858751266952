export const environment = {
  production: false,
  DomainURL: "",
  DomainDestination: "",  
  APIURL: "https://cms.api.dgkchain.com/api/v1/",
  APIRootURL: "https://cms.api.dgkchain.com/",
  APIUploadURL: "https://api.upload.dgkchain.com/api/v1/",
  APIUploadRootURL: "https://api.upload.dgkchain.com/",
  LoginURL: "https://login.dgkchain.com/",
  Website: "https://dgkchain.com/",  
  IPRegistry: "https://ipv4.myexternalip.com/json",
  IPRegistryURL: "https://api.ipregistry.co/?key=tryout",
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  DialogConfigWidth60: "60%",
  InitializationString: "",
  InitializationNumber: 0,  
  DanhMucQuocGiaIDVietNam: 237,  
  DanhMucUngDungID: 1,  
  MapZoom: 8,
  Latitude: 10.624748091691929,
  Longitude: 105.9665262379964,
  PageSize: 10,
  MaptilerAPIKey: "6iFTqps4QVACLERa26MA",
  TokenDefault: "d85df3cb-5688-4b1d-b120-50479b23d2a0",
  Token: "Token",
  TokenFCM: "TokenFCM",
  MaSo: "00000000",
  Homepage: "Homepage",
  Login: "Login",
  Bearer: "Bearer null",
  ThanhVienToChucID: "ThanhVienToChucID",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  ThanhVienDiaChiVi: "ThanhVienDiaChiVi",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  ToChucExists: "Tổ chức đã tồn tại.",
  PageTitle: "Hệ sinh thái Blockchain",
  PageTitleShort: "Blockchain",
  PageDescription: "Blockchain",
};
