import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienBlock } from 'src/app/shared/ThanhVienBlock.model';
import { ThanhVienBlockService } from 'src/app/shared/ThanhVienBlock.service';
import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { ThanhVienBlockTransationService } from 'src/app/shared/ThanhVienBlockTransation.service';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';


@Component({
  selector: 'app-thanh-vien-block-detail',
  templateUrl: './thanh-vien-block-detail.component.html',
  styleUrls: ['./thanh-vien-block-detail.component.css']
})
export class ThanhVienBlockDetailComponent implements OnInit {

  @ViewChild('ThanhVienBlockTransationSort') ThanhVienBlockTransationSort: MatSort;
  @ViewChild('ThanhVienBlockTransationPaginator') ThanhVienBlockTransationPaginator: MatPaginator;
  

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ThanhVienBlockDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public ThanhVienService: ThanhVienService,
    public ThanhVienBlockService: ThanhVienBlockService,
    public ThanhVienBlockTransationService: ThanhVienBlockTransationService,

   
    
  ) { }

  ngOnInit(): void {
    this.ThanhVienBlockSearch();
  }
  DateNgayGhiNhan(value) {
    this.ThanhVienBlockService.FormData.NgayGhiNhan = new Date(value);
  }
  ThanhVienBlockSave() {
    this.ThanhVienBlockService.IsShowLoading = true;    
    this.ThanhVienBlockService.SaveAsync().subscribe(
      res => {
        this.ThanhVienBlockService.FormData = res as ThanhVienBlock;
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThanhVienBlockService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienBlockService.IsShowLoading = false;
      }
    );
  }
  ThanhVienBlockSearch() {
    this.ThanhVienBlockService.BaseParameter.ID = this.ThanhVienBlockService.FormData.ID;
    this.ThanhVienBlockService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienBlockService.FormData = res as ThanhVienBlock;               
      },
      err => {
      }
    );
  }
  ThanhVienBlockTransationSearch() {
    if (this.ThanhVienBlockTransationService.BaseParameter.SearchString.length > 0) {
      this.ThanhVienBlockTransationService.DataSource.filter = this.ThanhVienBlockTransationService.BaseParameter.SearchString.toLowerCase();
    }
    else {
      this.ThanhVienBlockService.IsShowLoading = true;
      this.ThanhVienBlockTransationService.BaseParameter.ParentID = this.ThanhVienBlockService.FormData.ID;
      this.ThanhVienBlockTransationService.GetByParentIDToListAsync().subscribe(
        res => {
          this.ThanhVienBlockTransationService.List = (res as ThanhVienBlockTransation[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1));
          this.ThanhVienBlockTransationService.DataSource = new MatTableDataSource(this.ThanhVienBlockTransationService.List);
          this.ThanhVienBlockTransationService.DataSource.sort = this.ThanhVienBlockTransationSort;
          this.ThanhVienBlockTransationService.DataSource.paginator = this.ThanhVienBlockTransationPaginator;
          this.ThanhVienBlockService.IsShowLoading = false;
        },
        err => {
          this.ThanhVienBlockService.IsShowLoading = false;
        }
      );
    }
  }  
  ThanhVienAdd(ID: number) {
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {          
        });
      },
      err => {
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}