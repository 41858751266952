import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { ThanhVienBlockTransationService } from 'src/app/shared/ThanhVienBlockTransation.service';


@Component({
  selector: 'app-thanh-vien-block-transation-detail',
  templateUrl: './thanh-vien-block-transation-detail.component.html',
  styleUrls: ['./thanh-vien-block-transation-detail.component.css']
})
export class ThanhVienBlockTransationDetailComponent implements OnInit {  
  

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ThanhVienBlockTransationDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    
    public ThanhVienBlockTransationService: ThanhVienBlockTransationService,

   
    
  ) { }

  ngOnInit(): void {
    
  }
  DateNgayGhiNhan(value) {
    this.ThanhVienBlockTransationService.FormData.NgayGhiNhan = new Date(value);
  }
    Close() {
    this.dialogRef.close();
  }
}
