import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienBlockTransationService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'NgayGhiNhan', 'ParentName', 'Code', 'KhuVuc', 'TypeName', 'Description', 'DiaChiChuyen', 'DiaChiNhan', 'SoLuongTransation', 'SoLuongGas', 'SoLuongThang', 'ThanhTienSauCung'];
        
    List: ThanhVienBlockTransation[] | undefined;
    ListFilter: ThanhVienBlockTransation[] | undefined;
    FormData!: ThanhVienBlockTransation;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienBlockTransation";
    }
}

