<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div>
            <h2 class="mb-1">Nghị định
                số 13/2023/NĐ-CP về bảo vệ dữ liệu cá nhân được ban hành bởi Chính phủ
            </h2>
            <h6 class="m-0 text-dark fw-light">
                Chính Sách Bảo Mật
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThongBaoDetail" data-bs-toggle="tab"
                                    href="#ThongBaoDetail" role="tab" aria-controls="ThongBaoDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Nghị định số 13/2023/NĐ-CP</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThongBaoDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">                                        
                                        <h5>Kính chào Quý khách hàng,</h5>
                                        <h5>Ban quản trị phần mềm (ứng dụng), xin gửi đến quý khách hàng thông báo về
                                            việc xử lý dữ liệu cá nhân, điều khoản và điều kiện liên quan đến bảo mật dữ
                                            liệu cá nhân, theo Nghị định số 13/2023/NĐ-CP của Chính phủ.</h5>
                                        <h5>Chính sách bảo mật của chúng tôi đã được thông báo từ khi quý khách hàng tạo
                                            tài khoản để sử dụng phần mềm (ứng dụng). Theo đó, chúng tôi có thể thu thập
                                            và xử lý dữ liệu cá nhân của quý khách hàng, tuân thủ theo Chính sách bảo
                                            mật (quyền riêng tư) được xây dựng dựa trên quy định của Điều 68 đến Điều
                                            73, Nghị định 52/2013/NĐ-CP về bảo vệ thông tin cá nhân.</h5>
                                        <h5>Từ ngày 1/7/2023, chính sách của chúng tôi sẽ được cập nhật và bổ sung để
                                            phù hợp với Nghị định số 13/2023/NĐ-CP mới được ban hành. Điều này giúp đảm
                                            bảo rằng phần mềm (ứng dụng) của chúng tôi tuân thủ các quy định của pháp
                                            luật Việt Nam về bảo vệ dữ liệu cá nhân của quý khách hàng.</h5>
                                        <h5>Chúng tôi khuyến khích Quý khách hàng đọc lại các chính sách và quy định
                                            pháp lý này để hiểu rõ hơn về cách chúng tôi xử lý dữ liệu cá nhân trong quá
                                            trình sử dụng phần mềm (ứng dụng).</h5>
                                        <h5>Xin chân thành cảm ơn quý khách hàng.</h5>
                                        <h5>Trân trọng,</h5>
                                        <h5>Ban quản trị phần mềm (ứng dụng)</h5>
                                    </div>                                    
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a *ngIf="NotificationService.IsMobileDevice" href="https://datafiles.chinhphu.vn/cpp/files/vbpq/2023/4/13nd.signed.pdf">Link PDF</a>
                                        <iframe  *ngIf="!NotificationService.IsMobileDevice"  id="fred" style="border:1px solid #666CCC" src="https://datafiles.chinhphu.vn/cpp/files/vbpq/2023/4/13nd.signed.pdf" frameborder="1" scrolling="auto" height="1200" width="100%" ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>