<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-DanhMucDichVuDetail" data-bs-toggle="tab" href="#DanhMucDichVuDetail" role="tab"
                aria-controls="DanhMucDichVuDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Dịch vụ [{{DanhMucDichVuService.FormData.ID}}]</span>
            </a>
        </li>      
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="DanhMucDichVuDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="DanhMucDichVuSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="DanhMucDichVuService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>               
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">                   
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="DanhMucDichVuService.FormData.Name" placeholder="Tiêu đề" type="text"
                            class="form-control">
                    </div>       
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Code</label>
                        <input name="Code" [(ngModel)]="DanhMucDichVuService.FormData.Code" placeholder="Code" type="text"
                            class="form-control">
                    </div>    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô tả</label>
                        <input name="Description" [(ngModel)]="DanhMucDichVuService.FormData.Description" placeholder="Mô tả" type="text"
                            class="form-control">
                    </div>  
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="DanhMucDichVuService.FormData.Note" placeholder="Ghi chú" type="text"
                            class="form-control">
                    </div>                      
                </div>      
                <div class="col-lg-6 col-sm-12 col-12">                   
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Transation</label>
                        <input name="SoLuongTransation" [(ngModel)]="DanhMucDichVuService.FormData.SoLuongTransation" placeholder="Transation" type="number"
                            class="form-control" style="text-align: right;">
                    </div>       
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Gas</label>
                        <input name="SoLuongGas" [(ngModel)]="DanhMucDichVuService.FormData.SoLuongGas" placeholder="Gas" type="number"
                            class="form-control" style="text-align: right;">
                    </div>    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tháng</label>
                        <input name="SoLuongThang" [(ngModel)]="DanhMucDichVuService.FormData.SoLuongThang" placeholder="Tháng" type="number"
                            class="form-control" style="text-align: right;">
                    </div>    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giá</label>
                        <input name="GiaTri" [(ngModel)]="DanhMucDichVuService.FormData.GiaTri" placeholder="Giá" type="number"
                            class="form-control" style="text-align: right;">
                    </div>                          
                </div>                   
            </div>
        </div>
       
    </div>
</div>
<app-loading *ngIf="DanhMucDichVuService.IsShowLoading"></app-loading>