<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienBlockTransation" data-bs-toggle="tab"
                                    href="#ThanhVienBlockTransation" role="tab" aria-controls="ThanhVienBlockTransation"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Transaction</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienBlockTransation" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">                                        
                                        <input [(ngModel)]="ThanhVienBlockTransationService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ThanhVienBlockTransationSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">                                        
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienBlockTransationSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ThanhVienBlockTransationService.List">({{ThanhVienBlockTransationService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienBlockTransationSort="matSort"
                                                    [dataSource]="ThanhVienBlockTransationService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienBlockTransationPaginator">
                                                                {{ThanhVienBlockTransationPaginator.pageSize *
                                                                ThanhVienBlockTransationPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NgayGhiNhan |
                                                                date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Block
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.ParentName}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienBlockAdd(element.ParentID)"><b>{{element.ParentName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Token
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary" (click)="ThanhVienBlockTransationAdd(element.ID)"><b>{{element.Code}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KhuVuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Khu vực</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KhuVuc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TypeName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mô tả
                                                            (Function)</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChiChuyen">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.DiaChiChuyen}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienAdd(element.ThanhVienIDChuyen)"><b>{{element.DiaChiChuyen}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.DiaChiNhan}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienAdd(element.ThanhVienIDNhan)"><b>{{element.DiaChiNhan}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuongTransation">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.SoLuongTransation |
                                                                number:'1.0-4'}}
                                                        </td>
                                                    </ng-container>       
                                                    <ng-container matColumnDef="SoLuongGas">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gas
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.SoLuongGas |
                                                                number:'1.0-4'}}
                                                        </td>
                                                    </ng-container>    
                                                    <ng-container matColumnDef="SoLuongThang">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.SoLuongThang |
                                                                number:'1.0-4'}}
                                                        </td>
                                                    </ng-container>       
                                                    <ng-container matColumnDef="ThanhTienSauCung">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giá trị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ThanhTienSauCung |
                                                            number:'1.0-4'}}
                                                        </td>
                                                    </ng-container>                                          
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienBlockTransationService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienBlockTransationService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienBlockTransationPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienBlockTransationSort="matSort"
                                                    [dataSource]="ThanhVienBlockTransationService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">                                                            
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienBlockTransationService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienBlockTransationService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienBlockTransationPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienBlockTransationService.IsShowLoading"></app-loading>