<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ThanhVienDetail" data-bs-toggle="tab" href="#ThanhVienDetail" role="tab"
                aria-controls="ThanhVienDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thành viên
                    [{{ThanhVienService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienChucNang" data-bs-toggle="tab" href="#ThanhVienChucNang" role="tab"
                aria-controls="ThanhVienChucNang" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">Chức năng</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <a style="width: 100%;" title="Refresh Key Public" (click)="ThanhVienRefreshKeyPublic()"
                        class="btn btn-info">Refresh Key</a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ThanhVienService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsChungThuc"
                            [(ngModel)]="ThanhVienService.FormData.IsChungThuc" />
                        <label class="form-check-label" for="IsChungThuc">Chứng thực</label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsRoot"
                            [(ngModel)]="ThanhVienService.FormData.IsRoot" />
                        <label class="form-check-label" for="IsRoot">Root</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại [{{ThanhVienService.FormData.ParentID}}]</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="ThanhVienService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucThanhVienService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ứng dụng [{{ThanhVienService.FormData.DanhMucUngDungID}}]</label>
                        <select class="form-select" name="DanhMucUngDungID" [(ngModel)]="ThanhVienService.FormData.DanhMucUngDungID">
                            <option *ngFor="let item of DanhMucUngDungService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tài khoản</label>
                        <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormData.TaiKhoan"
                            [disabled]="ThanhVienService.FormData && ThanhVienService.FormData.ID>0"
                            placeholder="Tài khoản" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mật khẩu</label>
                        <input name="MatKhau" [(ngModel)]="ThanhVienService.FormData.MatKhau" placeholder="Mật khẩu"
                            type="password" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sender</label>
                        <input name="DiaChiVi" [(ngModel)]="ThanhVienService.FormData.DiaChiVi" placeholder="Sender"
                            type="text" disabled class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">URL</label>
                        <input name="TypeName" [(ngModel)]="ThanhVienService.FormData.TypeName" placeholder="URL"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="ThanhVienService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số thuế</label>
                        <input name="MaSoThue" [(ngModel)]="ThanhVienService.FormData.MaSoThue" placeholder="Mã số thuế"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">CCCD</label>
                        <input name="CCCD" [(ngModel)]="ThanhVienService.FormData.CCCD" placeholder="CCCD" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="DienThoai" [(ngModel)]="ThanhVienService.FormData.DienThoai"
                            placeholder="Điện thoại" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="Email" [(ngModel)]="ThanhVienService.FormData.Email" placeholder="Email"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="DiaChi" [(ngModel)]="ThanhVienService.FormData.DiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="ThanhVienService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Transaction</label>
                        <input name="Transation" [(ngModel)]="ThanhVienService.FormData.SoLuongTransation"
                            placeholder="Transaction" type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đã sử dụng</label>
                        <input name="SoLuongTransationDaSuDung"
                            [(ngModel)]="ThanhVienService.FormData.SoLuongTransationDaSuDung" placeholder="Đã sử dụng"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Còn lại</label>
                        <input name="SoLuongTransationConLai"
                            [(ngModel)]="ThanhVienService.FormData.SoLuongTransationConLai" placeholder="Còn lại"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Gas</label>
                        <input name="SoLuongGas" [(ngModel)]="ThanhVienService.FormData.SoLuongGas"
                            placeholder="Gas" type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đã sử dụng</label>
                        <input name="SoLuongGasDaSuDung"
                            [(ngModel)]="ThanhVienService.FormData.SoLuongGasDaSuDung" placeholder="Đã sử dụng"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Còn lại</label>
                        <input name="SoLuongGasConLai"
                            [(ngModel)]="ThanhVienService.FormData.SoLuongGasConLai" placeholder="Còn lại"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tháng</label>
                        <input name="SoLuongThang" [(ngModel)]="ThanhVienService.FormData.SoLuongThang"
                            placeholder="Tháng" type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đã sử dụng</label>
                        <input name="SoLuongThangDaSuDung"
                            [(ngModel)]="ThanhVienService.FormData.SoLuongThangDaSuDung" placeholder="Đã sử dụng"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Còn lại</label>
                        <input name="SoLuongThangConLai"
                            [(ngModel)]="ThanhVienService.FormData.SoLuongThangConLai" placeholder="Còn lại"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>     
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày bắt đầu</label>
                        <input [ngModel]="ThanhVienService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgayBatDau($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>               
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày hết hạn</label>
                        <input [ngModel]="ThanhVienService.FormData.NgayHetHan | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgayHetHan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Public key</label>
                        <textarea name="KeyPublic" [(ngModel)]="ThanhVienService.FormData.KeyPublic"
                            placeholder="Public key" type="text" disabled class="form-control" rows="8"></textarea>
                    </div>        
                </div>
                <div class="col-lg-4 col-sm-12 col-12">                    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">IP address</label>
                        <input name="IPAddress" [(ngModel)]="ThanhVienService.FormData.IPAddress"
                            placeholder="IP address" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">API</label>
                        <input name="API" [(ngModel)]="ThanhVienService.FormData.API" placeholder="API" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Khu vực</label>
                        <input name="KhuVuc" [(ngModel)]="ThanhVienService.FormData.KhuVuc"
                            placeholder="Khu vực" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quốc gia</label>
                        <input name="QuocGia" [(ngModel)]="ThanhVienService.FormData.QuocGia"
                            placeholder="Quốc gia" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỉnh thành</label>
                        <input name="TinhThanh" [(ngModel)]="ThanhVienService.FormData.TinhThanh"
                            placeholder="Tỉnh thành" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kinh độ</label>
                        <input name="KinhDo" [(ngModel)]="ThanhVienService.FormData.KinhDo" placeholder="Kinh độ"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vĩ độ</label>
                        <input name="ViDo" [(ngModel)]="ThanhVienService.FormData.ViDo" placeholder="Vĩ độ"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hình đại diện: </label>
                        <br />
                        <input type="file" (change)="ChangeFileName($event.target.files)">
                        <br />                        
                        <img src="{{ThanhVienService.FormData.FileName}}" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienChucNang" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-9 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienChucNangService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienChucNangSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThanhVienChucNangSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" name="ActiveAll"
                            (change)="ThanhVienChucNangActiveAllChange()" [(ngModel)]="ActiveAllThanhVienChucNang" />
                        <label class="form-check-label" for="ActiveAll">Tất cả</label>
                    </div>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Chức năng
                        <span *ngIf="ThanhVienChucNangService.List">({{ThanhVienChucNangService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #ThanhVienChucNangSort="matSort"
                                [dataSource]="ThanhVienChucNangService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        {{ThanhVienChucNangPaginator.pageSize *
                                        ThanhVienChucNangPaginator.pageIndex + i + 1}}
                                        [{{element.ID}}]
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức năng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Name}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                (change)="ThanhVienChucNangActiveChange(element)"
                                                name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienChucNangService.DisplayColumns001">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienChucNangService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator #ThanhVienChucNangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>