import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DonHangChiTiet } from 'src/app/shared/DonHangChiTiet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DonHangChiTietService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'DanhMucDichVuID', 'SoLuongTransation', 'SoLuongGas', 'SoLuongThang', 'SoLuong', 'DonGia', 'TongCong', 'Save'];
        
    List: DonHangChiTiet[] | undefined;
    ListFilter: DonHangChiTiet[] | undefined;
    FormData!: DonHangChiTiet;
    FormDataLogin!: DonHangChiTiet;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "DonHangChiTiet";
    }
}

