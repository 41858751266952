<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-DonHangDetail" data-bs-toggle="tab" href="#DonHangDetail" role="tab"
                aria-controls="DonHangDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Đơn hàng [{{DonHangService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-DonHangChiTiet" data-bs-toggle="tab" href="#DonHangChiTiet" role="tab"
                aria-controls="DonHangChiTiet" aria-selected="true">
                <span class="badge bg-danger" style="font-size: 16px;">Dịch vụ</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-DonHangThanhToan" data-bs-toggle="tab" href="#DonHangThanhToan" role="tab"
                aria-controls="DonHangThanhToan" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">Thanh toán</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-DonHangTapTinDinhKem" data-bs-toggle="tab" href="#DonHangTapTinDinhKem"
                role="tab" aria-controls="DonHangTapTinDinhKem" aria-selected="true">
                <span class="badge bg-primary" style="font-size: 16px;">Tập tin</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienBlock" data-bs-toggle="tab" href="#ThanhVienBlock"
                role="tab" aria-controls="ThanhVienBlock" aria-selected="true">
                <span class="badge bg-warning" style="font-size: 16px;">Block</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="DonHangDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="DonHangSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="DonHangService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsThanhToan"
                            [(ngModel)]="DonHangService.FormData.IsThanhToan" />
                        <label class="form-check-label" for="IsThanhToan">Thanh toán</label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsHoanThanh"
                            [(ngModel)]="DonHangService.FormData.IsHoanThanh"/>
                        <label class="form-check-label" for="IsHoanThanh">Hoàn thành</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thành viên [{{DonHangService.FormData.NguoiMuaID}}]</label>
                        <mat-select class="form-control" name="DonHangService.FormData.NguoiMuaID"
                            [(ngModel)]="DonHangService.FormData.NguoiMuaID" (selectionChange)="NguoiMuaChange()">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="ThanhVienFilter($event.target.value)">
                            <mat-option *ngFor="let item of ThanhVienService.ListFilter" [value]="item.ID">
                                {{item.Name}} - {{item.DienThoai}} - {{item.DiaChiVi}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Người mua</label>
                        <input name="NguoiMuaName" [(ngModel)]="DonHangService.FormData.NguoiMuaName"
                            placeholder="Người mua" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Send To</label>
                        <input name="NguoiMuaDiaChiVi" [(ngModel)]="DonHangService.FormData.NguoiMuaDiaChiVi"
                            placeholder="Send To" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi nhận</label>
                        <input [ngModel]="DonHangService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgayGhiNhan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="DonHangService.FormData.Name" placeholder="Tiêu đề" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="DonHangService.FormData.Note" placeholder="Ghi chú" type="text"
                            class="form-control">
                    </div>

                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Transation</label>
                        <input name="SoLuongTransation" [(ngModel)]="DonHangService.FormData.SoLuongTransation"
                            placeholder="Transation" type="number" disabled class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Gas</label>
                        <input name="SoLuongGas" [(ngModel)]="DonHangService.FormData.SoLuongGas" placeholder="Gas"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tháng</label>
                        <input name="SoLuongThang" [(ngModel)]="DonHangService.FormData.SoLuongThang"
                            placeholder="Tháng" type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thành tiền</label>
                        <input name="ThanhTienSauCung" [(ngModel)]="DonHangService.FormData.ThanhTienSauCung"
                            placeholder="Thành tiền" type="number" disabled class="form-control"
                            style="text-align: right; font-weight: bold;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thanh toán</label>
                        <input name="ThanhToan" [(ngModel)]="DonHangService.FormData.ThanhToan" placeholder="Thanh toán"
                            type="number" disabled class="form-control"
                            style="text-align: right; font-weight: bold; color: green;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Còn lại</label>
                        <input name="ConLai" [(ngModel)]="DonHangService.FormData.ConLai" placeholder="Còn lại"
                            type="number" disabled class="form-control"
                            style="text-align: right; font-weight: bold; color: red;">
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="DonHangChiTiet" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="DonHangChiTietService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="DonHangChiTietSearch()" disabled>
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="DonHangChiTietSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Dịch vụ
                        <span *ngIf="DonHangChiTietService.List">({{DonHangChiTietService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #DonHangChiTietSort="matSort"
                                [dataSource]="DonHangChiTietService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="DonHangChiTietPaginator">
                                            {{DonHangChiTietPaginator.pageSize *
                                            DonHangChiTietPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucDichVuID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dịch vụ</th>
                                    <td mat-cell *matCellDef="let element" style="width: 300px;">
                                        <select class="form-select" name="DanhMucDichVuID" *ngIf="element.ID==0"
                                            [(ngModel)]="element.DanhMucDichVuID" (change)="DanhMucDichVuChange(element)">
                                            <option *ngFor="let item of DanhMucDichVuService.List;" [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                        <select class="form-select" name="DanhMucDichVuID" disabled *ngIf="element.ID>0"
                                            [(ngModel)]="element.DanhMucDichVuID" (change)="DanhMucDichVuChange(element)">
                                            <option *ngFor="let item of DanhMucDichVuService.List;" [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                    </td>
                                </ng-container>                                
                                <ng-container matColumnDef="SoLuongTransation">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transation</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Transation"
                                            name="SoLuongTransation{{element.ID}}"
                                            [(ngModel)]="element.SoLuongTransation" style="text-align: right;" disabled>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLuongGas">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gas</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Gas"
                                            name="SoLuongGas{{element.ID}}" [(ngModel)]="element.SoLuongGas" disabled
                                            style="text-align: right;">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLuongThang">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Tháng"
                                            name="SoLuongThang{{element.ID}}" [(ngModel)]="element.SoLuongThang"
                                            disabled style="text-align: right;">
                                    </td>
                                </ng-container>                               
                                <ng-container matColumnDef="SoLuong">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Số lượng"
                                            name="SoLuong{{element.ID}}" [(ngModel)]="element.SoLuong"
                                            style="text-align: right;">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DonGia">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn giá</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Đơn giá"
                                            name="DonGia{{element.ID}}" [(ngModel)]="element.DonGia"
                                            style="text-align: right;">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TongCong">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Thành tiền</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Thành tiền"
                                            name="TongCong{{element.ID}}" [(ngModel)]="element.TongCong" disabled
                                            style="text-align: right;">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="DonHangChiTietSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="DonHangChiTietDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="DonHangChiTietService.DisplayColumns001">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: DonHangChiTietService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator #DonHangChiTietPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="DonHangThanhToan" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="DonHangThanhToanService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="DonHangThanhToanSearch()" disabled>
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="DonHangThanhToanSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Lịch sử thanh toán
                        <span *ngIf="DonHangThanhToanService.List">({{DonHangThanhToanService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #DonHangThanhToanSort="matSort"
                                [dataSource]="DonHangThanhToanService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="DonHangThanhToanPaginator">
                                            {{DonHangThanhToanPaginator.pageSize *
                                            DonHangThanhToanPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                            style="text-align: right;"
                                            (ngModelChange)="DonHangThanhToanDateNgayGhiNhan($event, element)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                            autocomplete="off">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Người thanh toán</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Người thanh toán"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ThanhToan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Thanh toán</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Thanh toán"
                                            name="ThanhToan{{element.ID}}" [(ngModel)]="element.ThanhToan"
                                            style="text-align: right; font-weight: bold; color: green;">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Ghi chú"
                                            name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="DonHangThanhToanSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="DonHangThanhToanDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="DonHangThanhToanService.DisplayColumns001">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: DonHangThanhToanService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator #DonHangThanhToanPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="DonHangTapTinDinhKem" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="DonHangTapTinDinhKemService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="DonHangTapTinDinhKemSearch()" disabled>
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="DonHangTapTinDinhKemSearch()"><i class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Tập tin đính kèm
                        <span *ngIf="DonHangTapTinDinhKemService.List">({{DonHangTapTinDinhKemService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #DonHangTapTinDinhKemSort="matSort"
                                [dataSource]="DonHangTapTinDinhKemService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="DonHangTapTinDinhKemPaginator">
                                            {{DonHangTapTinDinhKemPaginator.pageSize *
                                            DonHangTapTinDinhKemPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FileName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="file" style="width: 200px;"
                                            (change)="DonHangTapTinDinhKemChangeFileName($event.target.files)">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a *ngIf="element.ID > 0 && element.FileName" class="link-primary"
                                            href="{{element.FileName}}"><b>Tải về</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="DonHangTapTinDinhKemSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="DonHangTapTinDinhKemDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="DonHangTapTinDinhKemService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: DonHangTapTinDinhKemService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator #DonHangTapTinDinhKemPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="ThanhVienBlock" role="tabpanel">
            <div class="row gx-4">                                  
                <div class="col-lg-11 col-sm-12 col-12">                    
                    <input [(ngModel)]="ThanhVienBlockService.BaseParameter.SearchString"
                        placeholder="Tìm ..." class="form-control" (keyup.enter)="ThanhVienBlockSearch()" disabled>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">                    
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="ThanhVienBlockSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>                                    
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Block
                        <span *ngIf="ThanhVienBlockService.List">({{ThanhVienBlockService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #ThanhVienBlockSort="matSort" [dataSource]="ThanhVienBlockService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;"
                                        style="text-align: center;">
                                        <div *ngIf="ThanhVienBlockPaginator">
                                            {{ThanhVienBlockPaginator.pageSize *
                                            ThanhVienBlockPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.NgayGhiNhan |
                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                    </td>
                                </ng-container>                                                                  
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Token
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}"
                                            class="link-primary"
                                            (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TypeName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.TypeName}}
                                    </td>
                                </ng-container>      
                                <ng-container matColumnDef="Description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mô tả (Function)</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Description}}
                                    </td>
                                </ng-container>      
                                <ng-container matColumnDef="DiaChiChuyen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DiaChiChuyen}}
                                    </td>
                                </ng-container>                                        
                                <ng-container matColumnDef="DiaChiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DiaChiNhan}}
                                    </td>
                                </ng-container>                                                                                                                                                                                                        
                                <tr mat-header-row
                                    *matHeaderRowDef="ThanhVienBlockService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #ThanhVienBlockPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #ThanhVienBlockSort="matSort" [dataSource]="ThanhVienBlockService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">                                                                    
                                                <a class="form-label" style="cursor: pointer;"
                                                    title="{{element.Name}}" class="link-primary"
                                                    (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                                            </div>                                                                                                                                                                                               
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="ThanhVienBlockService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #ThanhVienBlockPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DonHangService.IsShowLoading"></app-loading>