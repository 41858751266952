import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ThanhVienBlock } from 'src/app/shared/ThanhVienBlock.model';
import { ThanhVienBlockService } from 'src/app/shared/ThanhVienBlock.service';
import { ThanhVienBlockDetailComponent } from '../thanh-vien-block-detail/thanh-vien-block-detail.component';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';


@Component({
  selector: 'app-thanh-vien-block',
  templateUrl: './thanh-vien-block.component.html',
  styleUrls: ['./thanh-vien-block.component.css']
})
export class ThanhVienBlockComponent implements OnInit {

  @ViewChild('ThanhVienBlockSort') ThanhVienBlockSort: MatSort;
  @ViewChild('ThanhVienBlockPaginator') ThanhVienBlockPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public ThanhVienService: ThanhVienService,
    public ThanhVienBlockService: ThanhVienBlockService,


  ) { }

  ngOnInit(): void {
    this.ThanhVienBlockSearch();
  }
  ThanhVienBlockSearch() {
    this.ThanhVienBlockService.IsShowLoading = true;
    if (this.ThanhVienBlockService.BaseParameter.SearchString.length > 0) {
      this.ThanhVienBlockService.GetBySearchStringToListAsync().subscribe(
        res => {
          this.ThanhVienBlockService.List = (res as ThanhVienBlock[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1));
          this.ThanhVienBlockService.DataSource = new MatTableDataSource(this.ThanhVienBlockService.List);
          this.ThanhVienBlockService.DataSource.sort = this.ThanhVienBlockSort;
          this.ThanhVienBlockService.DataSource.paginator = this.ThanhVienBlockPaginator;
          this.ThanhVienBlockService.IsShowLoading = false;
        },
        err => {
          this.ThanhVienBlockService.IsShowLoading = false;
        }
      );
    }
    else {
      this.ThanhVienBlockService.GetByPageAndPageSizeToListAsync().subscribe(
        res => {
          this.ThanhVienBlockService.List = (res as ThanhVienBlock[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1));
          this.ThanhVienBlockService.DataSource = new MatTableDataSource(this.ThanhVienBlockService.List);
          this.ThanhVienBlockService.DataSource.sort = this.ThanhVienBlockSort;
          this.ThanhVienBlockService.DataSource.paginator = this.ThanhVienBlockPaginator;
          this.ThanhVienBlockService.IsShowLoading = false;
        },
        err => {
          this.ThanhVienBlockService.IsShowLoading = false;
        }
      );
    }
  }
  ThanhVienBlockAdd(ID: number) {
    this.ThanhVienBlockService.BaseParameter.ID = ID;
    this.ThanhVienBlockService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienBlockService.FormData = res as ThanhVienBlock;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienBlockDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienBlockSearch();
        });
      },
      err => {
      }
    );
  }
  ThanhVienAdd(ID: number) {
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {          
        });
      },
      err => {
      }
    );
  }
}