<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVien" data-bs-toggle="tab" href="#ThanhVien"
                                    role="tab" aria-controls="ThanhVien" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thành viên</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVien" role="tabpanel">
                                <div class="row gx-4">                                  
                                    <div class="col-lg-10 col-sm-12 col-12">                                        
                                        <input [(ngModel)]="ThanhVienService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control" (keyup.enter)="ThanhVienSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">                                        
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">                                        
                                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                            (click)="ThanhVienAdd(0)"><i class="bi bi-plus"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="ThanhVienService.List">({{ThanhVienService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienSort="matSort" [dataSource]="ThanhVienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienPaginator">
                                                                {{ThanhVienPaginator.pageSize *
                                                                ThanhVienPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân quyền
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ParentName}}
                                                        </td>
                                                    </ng-container>     
                                                    <ng-container matColumnDef="TaiKhoan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài khoản
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.TaiKhoan}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienAdd(element.ID)"><b>{{element.TaiKhoan}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChiVi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DiaChiVi}}
                                                        </td>
                                                    </ng-container>                                                                                                                                
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsChungThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsChungThuc{{element.ID}}"
                                                                    [(ngModel)]="element.IsChungThuc">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsRoot">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Root
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsRoot{{element.ID}}"
                                                                    [(ngModel)]="element.IsRoot">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienSort="matSort" [dataSource]="ThanhVienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">                                                                    
                                                                    <a class="form-label" style="cursor: pointer;"
                                                                        title="{{element.Name}}" class="link-primary"
                                                                        (click)="ThanhVienAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                                </div>                                                                                                                               
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-check-label"
                                                                            for="Active">Kích hoạt</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>