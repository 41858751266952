<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVien" data-bs-toggle="tab" href="#ThanhVien"
                                    role="tab" aria-controls="ThanhVien" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVien" role="tabpanel">
                                <div class="row gx-4">              
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()" class="btn btn-info"><i
                                                class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Refresh Key Public" (click)="ThanhVienRefreshKeyPublic()"
                                            class="btn btn-info"><i class="fa fa-save"></i> Refresh Key public</a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active" disabled
                                                [(ngModel)]="ThanhVienService.FormDataLogin.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tài khoản</label>
                                            <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormDataLogin.TaiKhoan"
                                                [disabled]="ThanhVienService.FormDataLogin && ThanhVienService.FormDataLogin.ID>0"
                                                placeholder="Tài khoản" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mật khẩu</label>
                                            <input name="MatKhau" [(ngModel)]="ThanhVienService.FormDataLogin.MatKhau" placeholder="Mật khẩu"
                                                type="password" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ ví</label>
                                            <input name="DiaChiVi" [(ngModel)]="ThanhVienService.FormDataLogin.DiaChiVi" placeholder="Địa chỉ ví" type="text" disabled
                                                class="form-control" disabled>
                                        </div>                          
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hình đại diện: </label>
                                            <br />
                                            <input type="file" (change)="ChangeFileName($event.target.files)">
                                            <br />                        
                                            <img src="{{ThanhVienService.FormDataLogin.FileName}}" class="img-fluid">
                                        </div>             
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="ThanhVienService.FormDataLogin.Name" placeholder="Tiêu đề" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số thuế</label>
                                            <input name="MaSoThue" [(ngModel)]="ThanhVienService.FormDataLogin.MaSoThue" placeholder="Mã số thuế" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">CCCD</label>
                                            <input name="CCCD" [(ngModel)]="ThanhVienService.FormDataLogin.CCCD"
                                                placeholder="CCCD" type="text" class="form-control">
                                        </div>                                  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ThanhVienService.FormDataLogin.DienThoai" placeholder="Điện thoại"
                                                type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ThanhVienService.FormDataLogin.Email" placeholder="Email" type="text"
                                                class="form-control">
                                        </div>       
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ThanhVienService.FormDataLogin.DiaChi" placeholder="Địa chỉ" type="text"
                                                class="form-control">
                                        </div>      
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ThanhVienService.FormDataLogin.Note" placeholder="Ghi chú" type="text"
                                                class="form-control">
                                        </div>        
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">     
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Transation</label>
                                            <input name="Transation" [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongTransation"
                                                placeholder="Transation" type="number" disabled class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đã sử dụng</label>
                                            <input name="SoLuongTransationDaSuDung"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongTransationDaSuDung" placeholder="Đã sử dụng"
                                                type="number" disabled class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Còn lại</label>
                                            <input name="SoLuongTransationConLai"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongTransationConLai" placeholder="Còn lại"
                                                type="number" disabled class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Gas</label>
                                            <input name="SoLuongGas" [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongGas"
                                                placeholder="Gas" type="number" disabled class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đã sử dụng</label>
                                            <input name="SoLuongGasDaSuDung"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongGasDaSuDung" placeholder="Đã sử dụng"
                                                type="number" disabled class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Còn lại</label>
                                            <input name="SoLuongGasConLai"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongGasConLai" placeholder="Còn lại"
                                                type="number" disabled class="form-control" style="text-align: right;">
                                        </div>                                                   
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày hết hạn</label>
                                            <input [ngModel]="ThanhVienService.FormDataLogin.NgayHetHan | date:'yyyy-MM-dd'"
                                                style="text-align: right;" (ngModelChange)="DateNgayHetHan($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off" disabled>
                                        </div>   
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Public key</label>
                                            <textarea name="KeyPublic" [(ngModel)]="ThanhVienService.FormDataLogin.KeyPublic"
                                                placeholder="Public key" type="text" disabled class="form-control" rows="8"></textarea>
                                        </div>                                                                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>