import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucDichVu } from 'src/app/shared/DanhMucDichVu.model';
import { DanhMucDichVuService } from 'src/app/shared/DanhMucDichVu.service';

@Component({
  selector: 'app-danh-muc-dich-vu-detail',
  templateUrl: './danh-muc-dich-vu-detail.component.html',
  styleUrls: ['./danh-muc-dich-vu-detail.component.css']
})
export class DanhMucDichVuDetailComponent implements OnInit {

  @ViewChild('DanhMucDichVuChucNangSort') DanhMucDichVuChucNangSort: MatSort;
  @ViewChild('DanhMucDichVuChucNangPaginator') DanhMucDichVuChucNangPaginator: MatPaginator;

  ActiveAllDanhMucDichVuChucNang: boolean = false;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DanhMucDichVuDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucDichVuService: DanhMucDichVuService,
    
  ) { }

  ngOnInit(): void {
    this.DanhMucDichVuSearch();
  }
  DanhMucDichVuSearch() {
    this.DanhMucDichVuService.BaseParameter.ID = this.DanhMucDichVuService.FormData.ID;
    this.DanhMucDichVuService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucDichVuService.FormData = res as DanhMucDichVu;               
      },
      err => {
      }
    );
  }
  
  DanhMucDichVuSave() {
    this.DanhMucDichVuService.IsShowLoading = true;
    this.DanhMucDichVuService.SaveAsync().subscribe(
      res => {
        this.DanhMucDichVuService.FormData = res as DanhMucDichVu;
        this.NotificationService.warn(environment.SaveSuccess);
        this.DanhMucDichVuService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DanhMucDichVuService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}