import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms'; 
import { NotificationService } from 'src/app/shared/Notification.service';



import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';
// import { ThanhVienChucNangDetailComponent } from '../thanh-vien-chuc-nang-detail/thanh-vien-chuc-nang-detail.component';

@Component({
  selector: 'app-danh-muc-chuc-nang',
  templateUrl: './danh-muc-chuc-nang.component.html',
  styleUrls: ['./danh-muc-chuc-nang.component.css']
})
export class DanhMucChucNangComponent implements OnInit {

  @ViewChild('DanhMucChucNangSort') DanhMucChucNangSort: MatSort;
  @ViewChild('DanhMucChucNangPaginator') DanhMucChucNangPaginator: MatPaginator;

  DanhMucUngDungID: number = environment.InitializationNumber;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public DanhMucChucNangService: DanhMucChucNangService,
  ) { }

  ngOnInit(): void {    
    this.DanhMucChucNangSearch();
  }
  DanhMucChucNangSearch() {   
    if (this.DanhMucChucNangService.BaseParameter.SearchString.length > 0) {
      this.DanhMucChucNangService.DataSource.filter = this.DanhMucChucNangService.BaseParameter.SearchString.toLowerCase();
    }
    else {
      this.DanhMucChucNangService.IsShowLoading = true;
      this.DanhMucChucNangService.GetAllAndEmptyToListAsync().subscribe(
        res => {
          this.DanhMucChucNangService.List = (res as DanhMucChucNang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucChucNangService.ListFilter = this.DanhMucChucNangService.List.filter(item => item.ParentID == 0);
          this.DanhMucChucNangService.DataSource = new MatTableDataSource(this.DanhMucChucNangService.List);
          this.DanhMucChucNangService.DataSource.sort = this.DanhMucChucNangSort;
          this.DanhMucChucNangService.DataSource.paginator = this.DanhMucChucNangPaginator;
          this.DanhMucChucNangService.IsShowLoading = false;
        },
        err => {
          this.DanhMucChucNangService.IsShowLoading = false;
        }
      );
    }
  }

  DanhMucChucNangSave(element: DanhMucChucNang) {
    this.DanhMucChucNangService.IsShowLoading = true;
    this.DanhMucChucNangService.FormData = element;
    this.DanhMucChucNangService.SaveAsync().subscribe(
      res => {
        this.DanhMucChucNangSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      }
    );
  }

  DanhMucChucNangDelete(element: DanhMucChucNang) {
    if (confirm(environment.DeleteConfirm)) {
      this.DanhMucChucNangService.IsShowLoading = true;
      this.DanhMucChucNangService.BaseParameter.ID = element.ID;
      this.DanhMucChucNangService.RemoveAsync().subscribe(
        res => {
          this.DanhMucChucNangSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.DanhMucChucNangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.DanhMucChucNangService.IsShowLoading = false;
        }
      );
    }
  }
  DanhMucChucNangAdd(element: DanhMucChucNang) {    
    this.DanhMucChucNangService.FormData = element;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = environment.DialogConfigWidth;
    dialogConfig.data = { ID: element.ID };
    //const dialog = this.dialog.open(ThanhVienChucNangDetailComponent, dialogConfig);
    //dialog.afterClosed().subscribe(() => {
   // });
  }
}