import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanh-vien-chuc-nang',
  templateUrl: './thanh-vien-chuc-nang.component.html',
  styleUrls: ['./thanh-vien-chuc-nang.component.css']
})
export class ThanhVienChucNangComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
