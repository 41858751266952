<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ThanhVienBlockDetail" data-bs-toggle="tab" href="#ThanhVienBlockDetail"
                role="tab" aria-controls="ThanhVienBlockDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Block
                    [{{ThanhVienBlockService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienBlockTransation" data-bs-toggle="tab" href="#ThanhVienBlockTransation"
                role="tab" aria-controls="ThanhVienBlockTransation" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">Transaction</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ThanhVienBlockDetail" role="tabpanel">
            <div class="row gx-4" *ngIf="ThanhVienBlockService.FormData.ID==0">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienBlockSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>                
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Token (Tự động)</label>
                        <input name="Code" [(ngModel)]="ThanhVienBlockService.FormData.Code" placeholder="Token"
                            type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi nhận (Tự động)</label>
                        <input [ngModel]="ThanhVienBlockService.FormData.NgayGhiNhan | date:'yyyy-MM-dd HH:mm:ss'"
                            style="text-align: right;" (ngModelChange)="DateNgayGhiNhan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="datetime" class="form-control" autocomplete="off" disabled>
                    </div>                    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sender</label>
                        <input name="DiaChiChuyen" [(ngModel)]="ThanhVienBlockService.FormData.DiaChiChuyen"
                            placeholder="Sender" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Send To</label>
                        <input name="DiaChiNhan" [(ngModel)]="ThanhVienBlockService.FormData.DiaChiNhan"
                            placeholder="Send To" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Khu vực</label>
                        <input name="KhuVuc" [(ngModel)]="ThanhVienBlockService.FormData.KhuVuc"
                            placeholder="Khu vực" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Type</label>
                        <input name="TypeName" [(ngModel)]="ThanhVienBlockService.FormData.TypeName" placeholder="Type"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô tả (Function)</label>
                        <input name="Description" [(ngModel)]="ThanhVienBlockService.FormData.Description"
                            placeholder="Mô tả (Function)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giá trị</label>
                        <input name="ThanhTienSauCung" [(ngModel)]="ThanhVienBlockService.FormData.ThanhTienSauCung"
                            placeholder="Giá trị" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Transaction</label>
                        <input name="SoLuongTransation" [(ngModel)]="ThanhVienBlockService.FormData.SoLuongTransation"
                            placeholder="Transaction" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Gas</label>
                        <input name="SoLuongGas" [(ngModel)]="ThanhVienBlockService.FormData.SoLuongGas"
                            placeholder="Gas" type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tháng</label>
                        <input name="SoLuongThang" [(ngModel)]="ThanhVienBlockService.FormData.SoLuongThang"
                            placeholder="Tháng" type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="ThanhVienBlockService.FormData.Note"
                            placeholder="Ghi chú" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Content</label>
                        <textarea name="HTMLContent" [(ngModel)]="ThanhVienBlockService.FormData.HTMLContent"
                            placeholder="Content" type="text" class="form-control" rows="30"></textarea>
                    </div> 
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienBlockService.FormData.ID>0" class="tab-pane fade" id="ThanhVienBlockTransation"
            role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienBlockTransationService.BaseParameter.SearchString"
                        placeholder="Tìm ..." class="form-control" (keyup.enter)="ThanhVienBlockTransationSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="ThanhVienBlockTransationSearch()"><i class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Transation
                        <span
                            *ngIf="ThanhVienBlockTransationService.List">({{ThanhVienBlockTransationService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #ThanhVienBlockTransationSort="matSort"
                                [dataSource]="ThanhVienBlockTransationService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ThanhVienBlockTransationPaginator">
                                            {{ThanhVienBlockTransationPaginator.pageSize *
                                            ThanhVienBlockTransationPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.NgayGhiNhan |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ParentName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Block
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.ParentName}}"
                                            class="link-primary"
                                            (click)="ThanhVienBlockAdd(element.ParentID)"><b>{{element.ParentName}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Token
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}"
                                            class="link-primary"><b>{{element.Code}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="KhuVuc">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Khu vực</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.KhuVuc}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TypeName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.TypeName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mô tả (Function)</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Description}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DiaChiChuyen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.DiaChiChuyen}}"
                                            class="link-primary"
                                            (click)="ThanhVienAdd(element.ThanhVienIDChuyen)"><b>{{element.DiaChiChuyen}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DiaChiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.DiaChiNhan}}"
                                            class="link-primary"
                                            (click)="ThanhVienAdd(element.ThanhVienIDNhan)"><b>{{element.DiaChiNhan}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLuongTransation">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.SoLuongTransation |
                                        number:'1.0-4'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLuongGas">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gas
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.SoLuongGas |
                                        number:'1.0-4'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLuongThang">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.SoLuongThang |
                                        number:'1.0-4'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ThanhTienSauCung">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Giá trị
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ThanhTienSauCung |
                                        number:'1.0-4'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienBlockTransationService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienBlockTransationService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator #ThanhVienBlockTransationPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienBlockService.IsShowLoading"></app-loading>