import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucGas } from 'src/app/shared/DanhMucGas.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucGasService extends BaseService{  
    
    DisplayColumns001: string[] = ['STT', 'ID', 'NhoNhat', 'LonNhat', 'Note', 'Active', 'Save'];

    List: DanhMucGas[] | undefined;
    ListFilter: DanhMucGas[] | undefined;
    FormData!: DanhMucGas;
    FormDataLogin!: DanhMucGas;

    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "DanhMucGas";
    }
}

