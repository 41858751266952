<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienBlock" data-bs-toggle="tab"
                                    href="#ThanhVienBlock" role="tab" aria-controls="ThanhVienBlock"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Block</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienBlock" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienBlockService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ThanhVienBlockSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienBlockSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                            (click)="ThanhVienBlockAdd(0)"><i class="bi bi-plus"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ThanhVienBlockService.List">({{ThanhVienBlockService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienBlockSort="matSort"
                                                    [dataSource]="ThanhVienBlockService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienBlockPaginator">
                                                                {{ThanhVienBlockPaginator.pageSize *
                                                                ThanhVienBlockPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NgayGhiNhan |
                                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Block
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TypeName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mô tả
                                                            (Function)</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChiChuyen">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.DiaChiChuyen}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienAdd(element.ParentID)"><b>{{element.DiaChiChuyen}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.DiaChiNhan}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienAdd(element.ThanhVienID)"><b>{{element.DiaChiNhan}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienBlockService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienBlockPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienBlockSort="matSort"
                                                    [dataSource]="ThanhVienBlockService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="form-label" style="cursor: pointer;"
                                                                        title="{{element.Name}}" class="link-primary"
                                                                        (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienBlockService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienBlockPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienBlockService.IsShowLoading"></app-loading>