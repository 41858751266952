import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { DanhMucDichVu } from 'src/app/shared/DanhMucDichVu.model';
import { DanhMucDichVuService } from 'src/app/shared/DanhMucDichVu.service';
import { DanhMucDichVuDetailComponent } from '../danh-muc-dich-vu-detail/danh-muc-dich-vu-detail.component';

@Component({
  selector: 'app-danh-muc-dich-vu',
  templateUrl: './danh-muc-dich-vu.component.html',
  styleUrls: ['./danh-muc-dich-vu.component.css']
})
export class DanhMucDichVuComponent implements OnInit {

  @ViewChild('DanhMucDichVuSort') DanhMucDichVuSort: MatSort;
  @ViewChild('DanhMucDichVuPaginator') DanhMucDichVuPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucDichVuService: DanhMucDichVuService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucDichVuSearch() {
    this.DanhMucDichVuService.SearchAllNotEmpty(this.DanhMucDichVuSort, this.DanhMucDichVuPaginator);
  } 
  DanhMucDichVuDelete(element: DanhMucDichVu) {
    this.DanhMucDichVuService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucDichVuService.ComponentDeleteAllNotEmpty(this.DanhMucDichVuSort, this.DanhMucDichVuPaginator));
  }  
  DanhMucDichVuAdd(ID: number) {
    this.DanhMucDichVuService.BaseParameter.ID = ID;
    this.DanhMucDichVuService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucDichVuService.FormData = res as DanhMucDichVu;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(DanhMucDichVuDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.DanhMucDichVuSearch();
        });
      },
      err => {
      }
    );
  }
}